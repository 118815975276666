import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './services/guards/auth.guard';
import { PrivacyComponent } from './pages/public/others/privacy/privacy.component';
import { TermsComponent } from './pages/public/others/terms/terms.component';
import { AlreadyLoggedInGuard } from './services/guards/alreadyLoggedIn.guard';
import { ConnectionErrorPageComponent } from './pages/public/connection-error-page/connection-error-page.component';
import { TeleprompterComponent } from './components/major/teleprompter/teleprompter.component';
import { AiPrompterComponent } from './components/major/teleprompter/ai-prompter/ai-prompter.component';
import { LottieTesterComponent } from './pages/public/lottie-tester/lottie-tester.component';
import { OnboardingMobileStepComponent } from './components/minor/onboarding-mobile-step/onboarding-mobile-step.component';
import { AssTesterComponent } from './pages/public/ass-tester/ass-tester.component';
import {
    DASHBOARD_HOME_URL,
    DASHBOARD_URL,
} from './pages/private/host/services/shared/constants/client/dashboard/dashboard.routes';

const routes: Routes = [
    { path: 'privacy', component: PrivacyComponent },
    { path: 'terms', component: TermsComponent },
    // {
    //     path: 'host/:id',
    //     redirectTo: '/dashboard/(panel:library)',
    // },
    {
        path: 'verify-invite',
        loadChildren: () =>
            import('./modules/public/verify-invite/verify-invite.module').then((m) => m.VerifyInviteModule),
    },
    {
        path: 'launch',
        loadChildren: () => import('./modules/launch/launch.module').then((m) => m.LaunchModule),
    },
    {
        path: 'cmw',
        loadChildren: () => import('./modules/launch/launch.module').then((m) => m.LaunchModule),
    },

    {
        path: 'onboarding',
        loadChildren: () => import('./modules/onboarding/onboarding.module').then((m) => m.OnboardingModule),
        data: { pageName: 'Onboarding' },
    },

    {
        path: 'onboarding/mobile',
        canActivate: [AuthGuard],
        component: OnboardingMobileStepComponent,
        data: { pageName: 'Onboarding-Mobile' },
    },
    {
        path: 'dashboard',
        pathMatch: 'full',
        redirectTo: '/dashboard/(panel:home)',
    },
    {
        path: 'dashboard',
        canActivate: [AuthGuard],
        canMatch: [AuthGuard],
        loadChildren: () => import('./modules/private/dashboard.module').then((m) => m.DashboardModule),
    },
    {
        path: 'quick',
        loadChildren: () => import('./modules/post2-vid/post2-vid.module').then((m) => m.Post2VidModule),
    },
    {
        path: 'participate/:hostUsername',
        redirectTo: '/dashboard/(panel:home)',
    },
    {
        path: 'join/:hostUsername',
        redirectTo: '/dashboard/(panel:home)',
    },

    { path: 'hoster', component: AssTesterComponent },
    { path: 'lottie', component: LottieTesterComponent },

    {
        path: 'login',
        canActivate: [AlreadyLoggedInGuard],
        loadChildren: () => import('./modules/sign-in/sign-in.module').then((m) => m.SignInModule),
    },
    {
        path: 'signin', // Alias for login
        redirectTo: 'login',
        pathMatch: 'full',
    },
    {
        path: 'register', // Another alias for login
        redirectTo: 'login',
        pathMatch: 'full',
    },

    // {path: 'feed', component: FeedComponent},
    { path: 'maintenance', component: ConnectionErrorPageComponent },
    { path: 'prompter', component: TeleprompterComponent },
    { path: 'ai-prompter', component: AiPrompterComponent },

    // { path: '', component: HomepageWpComponent }, // {path: '', component: MainComponent},
    { path: '', redirectTo: DASHBOARD_HOME_URL, pathMatch: 'full' },

    { path: '**', redirectTo: DASHBOARD_HOME_URL, pathMatch: 'full' },

    // Social media auth - needed for redirects
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            initialNavigation: 'enabledNonBlocking',
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
