import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class NavListenersService {
    private _isSideNavOpenSubject = new BehaviorSubject<boolean>(false);
    public isSideNavOpen$ = this._isSideNavOpenSubject.asObservable();

    constructor() {}

    public toggleSideNav() {
        this._isSideNavOpenSubject.next(!this._isSideNavOpenSubject.value);
    }
    set sideNavState(value: boolean) {
        this._isSideNavOpenSubject.next(value);
    }
}
