import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    QueryList,
    ViewChild,
    ViewChildren,
} from '@angular/core';
import { IOnBoardingQuestionConfigs, IOnboardingStep } from 'src/app/models/defines';
import { fadeInOnEnterAnimation, fadeOutOnLeaveAnimation } from 'angular-animations';
import { UserService } from '../../../services/api/auth/user.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthenticationService } from 'src/app/services/guards/authentication.service';
import { Subject } from 'rxjs';
import { MatStepper } from '@angular/material/stepper';
import { AnalyticsNotifierService, KEY_EVENT_TYPE } from '../../../services/utils/analytics-notifier.service';
import { FacebookPixelService } from '../../../services/utils/facebook-pixel.service';
import { OnboardingService } from '../../../services/onboarding.service';
import { ProfileService } from 'src/app/services/show/profile.service';
import { isWorkEmail, NonWorkEmailProviders } from 'src/app/utils/non-work-emails';
import { FormControl, NgForm, NgModel } from '@angular/forms';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { Router } from '@angular/router';
import { ConfigurationAuthApiService } from 'src/app/services/api/auth/config-auth-api.service';
import { IntercomService } from 'src/app/services/intercom.service';
import { RandomizeStateManagerService } from '../../../services/state-management/randomize/randomize-state-manager.service';

interface ILoadingData {
    isLoading: boolean;
    text?: string;
}

@Component({
    selector: 'app-onboarding',
    templateUrl: './onboarding.component.html',
    styleUrls: ['./onboarding.component.scss'],
    animations: [fadeInOnEnterAnimation({ duration: 1000 }), fadeOutOnLeaveAnimation({ duration: 1000, delay: 500 })],
})
export class OnboardingComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('stepper') stepper: MatStepper;
    @ViewChildren('questionInput') questionInputs: QueryList<NgModel>;

    selectedStep = 0;
    headerPosition: 'top' | 'bottom' = 'bottom';
    @ViewChildren('stepForm') forms: QueryList<NgForm>;
    initialized = false;
    currentForm: NgForm | null = null;
    loading = true;
    steps: IOnboardingStep[] = [];
    baseCdnUrl: string;
    loadingData: ILoadingData = {
        isLoading: true,
        text: 'Loading...',
    };
    tokenErrorText: string;
    toDsiplayMailTo: boolean = false;
    onDestroy$ = new Subject<boolean>();
    START_ONBOARDING_EVENT_SENT_LOCAL_STORAGE_KEY = 'startOnboardingEventSent';

    constructor(
        private config: ConfigurationService,
        private cdr: ChangeDetectorRef,
        breakpointObserver: BreakpointObserver,
        private authService: AuthenticationService,
        private configurationApi: ConfigurationAuthApiService,
        private userService: UserService,
        private router: Router,
        private _snackBar: MatSnackBar,
        private facebookPixelService: FacebookPixelService,
        private onboardingService: OnboardingService,
        private analyticsNotfier: AnalyticsNotifierService,
        private profileService: ProfileService,
        private intercomService: IntercomService,
        private randomizeStateManagerService: RandomizeStateManagerService
    ) {
        this.baseCdnUrl = this.config.baseCdnUrl;
        breakpointObserver.observe([Breakpoints.HandsetPortrait]).subscribe((result) => {
            if (result.matches) {
                this.headerPosition = 'top';
            } else {
                this.headerPosition = 'bottom';
            }
        });
    }

    ngOnInit() {
        //sending 'start onboarding' event once
        const onboardingEventSent = localStorage.getItem(this.START_ONBOARDING_EVENT_SENT_LOCAL_STORAGE_KEY);

        if (!onboardingEventSent) {
            console.log('Onboarding Event Sent:', onboardingEventSent);
            this.sendOnboardingStartKeyEvent();
            localStorage.setItem(this.START_ONBOARDING_EVENT_SENT_LOCAL_STORAGE_KEY, 'true');
        }

        this.getStepConfigurationAsync();

        this.onboardingService.requestImportBrandIfNeeded(false, null, false, false, null, {
            entity: 'user',
            id: null,
        });
    }

    sendOnboardingStartKeyEvent() {
        this.analyticsNotfier.notifyKeyEvent(this.profileService.user$.value, KEY_EVENT_TYPE.STARTED_ONBOARDING);
    }

    async getStepConfigurationAsync() {
        try {
            const config = await this.configurationApi.getOnboardingConfig();
            let index = 0;
            for (const step of config.steps) {
                const formGroup: FormControl[] = [];
                for (const question of step.questions) {
                    if (question?.id === 'name' && this.authService?.user?.name) {
                        question.value = this.authService.user.name;
                    } else {
                        question.value = question.value ?? undefined;
                    }
                    // Randomize options if present
                    if (question.options) {
                        const randomizedState = await this.randomizeStateManagerService.getRandomStatusAsync();
                        if (randomizedState.on_boarding_questions) {
                            const key = `onboarding_questions_${index}`;
                            question.options = this.randomizeStateManagerService.getAndStoreRandomizedState(
                                key,
                                question.options
                            );
                        }
                        index++;
                    }
                }
            }
            const brandingStep: IOnboardingStep = {
                id: 'branding',
                stepTitle: null,
                topIcon: '/files/platform/onboarding/branding.json',
                questions: null,
            };
            config.steps = [...config.steps, brandingStep];
            this.steps = config.steps;
        } catch (e) {
            console.error(e);
        } finally {
            this.loadingData = {
                isLoading: false,
            };
        }
    }

    ngAfterViewInit() {
        this.intercomService.shutdown();

        this.cdr.detectChanges();
    }

    stepChanged() {
        this.cdr.detectChanges();
        setTimeout(async () => {
            this.currentForm = this.forms.toArray()[this.selectedStep];
        });
    }

    stepBack() {
        setTimeout(() => {
            if (this.selectedStep > 0) {
                this.selectedStep = this.selectedStep - 1;
            }
            this.cdr.detectChanges();
        }, 0);
    }

    tryToProceed(form: NgForm) {
        setTimeout(async () => {
            if (!form.valid) {
                console.log('Form is invalid. Cannot proceed to the next step.');
                return;
            }

            this.loadingData = {
                isLoading: false,
            };
            this.goToNextStep();
        }, 0);
    }

    showErrorSnackbar() {
        const message = '😞 An error occurred, try to start over!';
        const snackbar = this._snackBar.open(message, 'Reload', {
            duration: 15000,
        });
        snackbar.onAction().subscribe(() => {
            snackbar.dismiss();
            location.reload();
        });
    }

    async finish() {
        try {
            this.loading = true;
            this.loadingData = {
                isLoading: true,
                text: 'Loading...',
            };

            const answers: IOnBoardingQuestionConfigs[] = [].concat(
                ...this.steps
                    .filter((step) => {
                        return step.questions?.length > 0;
                    })
                    .map((step) => {
                        return step.questions.map((question, index) => {
                            return {
                                id: question.id,
                                value: question.value,
                                hubspotPropertyId: question.hubspotPropertyId,
                                index:
                                    question.options?.length > 0 ? question.options.indexOf(question.value) + 1 : null,
                            } as IOnBoardingQuestionConfigs;
                        });
                    })
            );
            this.analyticsNotfier.pushTag({
                event: 'Finished Onboarding',
                data: { answers: answers },
            });

            const company = this.profileService.user$.value.branding?.company;
            if (company) {
                const nameToCheck = company.name ?? company.domain ?? '';
                const companyName = nameToCheck.trim();
                console.log(`Company: ${companyName}`);
            }
            console.log('answers', answers);

            console.log('authuser', this.authService.getUser());

            const user = this.authService.getUser();
            const me = this.userService.finishOnboarding(answers).subscribe({
                next: (user) => {
                    this.profileService.user$.next(user);
                    if (user.active) {
                        this.router.navigate(['dashboard']);
                    } else {
                        console.error('User is not active even after finishing the onboarding', me);
                        this.showErrorSnackbar();
                    }
                },
                error: (error) => {
                    this.loadingData.isLoading = false;
                    console.error('User is not active even after finishing the onboarding', me);
                    this.showErrorSnackbar();
                },
            });
        } catch (e) {
            console.error(e);
            this.showErrorSnackbar();
        }
    }

    ngOnDestroy(): void {
        this.onDestroy$.next(true);
        this.onDestroy$.complete();
    }

    private goToNextStep() {
        setTimeout(async () => {
            if (this.steps.length > this.selectedStep + 1) {
                this.selectedStep = this.selectedStep + 1;
            } else if (this.steps.length === this.selectedStep + 1) {
                this.finish();
            }
            this.cdr.detectChanges();
        }, 0);
    }

    private markInvalidControls() {
        this.questionInputs.forEach((questionInput: NgModel) => {
            if (questionInput.invalid) {
                questionInput.control.markAsTouched();
            }
        });
    }
}
