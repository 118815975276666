import { Component, OnInit } from '@angular/core';
import { FormGroup, UntypedFormBuilder } from '@angular/forms';
import {
    ActivatedRoute,
    NavigationCancel,
    NavigationEnd,
    NavigationError,
    NavigationStart,
    Router,
} from '@angular/router';
import { AuthenticationService } from './services/guards/authentication.service';
import { NavigationHelperService } from './services/utils/navigation-helper.service';
import { ProfileService } from './services/show/profile.service';
import { ROLE } from './models/defines';
import { ConsoleToggleService } from './services/utils/console-toggle.service';
import { ConfigurationService } from './services/configuration.service';
import { AnalyticsNotifierService } from './services/utils/analytics-notifier.service';
import { PathHelperService } from './services/utils/path-helper.service';
import { Subscription, take } from 'rxjs';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    host: {
        '(document:keydown)': 'handleKeyboardEvents($event)',
    },
})
export class AppComponent implements OnInit {
    title = 'shuffll';
    options: FormGroup;
    loading = true;
    userRole: ROLE = null;
    ROLE = ROLE;

    sessionTimer: any = null;
    sessionTimeInMinutes: number = 0;
    private subscriptions: Subscription = new Subscription();

    constructor(
        fb: UntypedFormBuilder,
        public auth: AuthenticationService,
        private navigationService: NavigationHelperService,
        public router: Router,
        public profileService: ProfileService,
        private consoleToggleService: ConsoleToggleService,
        private configService: ConfigurationService,
        public analyticsNotifier: AnalyticsNotifierService,
        public pathHelperService: PathHelperService,
        public activatedRoute: ActivatedRoute
    ) {
        // this.indexCacheService.openDatabaseAsync('myIndexDb', 1);
        this.profileService.role$.asObservable().subscribe((recievedRole) => {
            this.userRole = recievedRole;
        });

        this.options = fb.group({
            bottom: 0,
            fixed: false,
            top: 0,
        });

        this.router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                this.loading = true;

                // console.log("Navigation start")
            }
            if (
                event instanceof NavigationEnd ||
                event instanceof NavigationError ||
                event instanceof NavigationCancel
            ) {
                this.loading = false;
                // console.log("Navigation End")
            }
        });
        if (this.configService.trackUserEvents) {
            // Google / mixpanel analytics and invite ID
            this.router.events.subscribe((event) => {
                if (event instanceof NavigationEnd) {
                    // Will return the full path after the / - like: /host/isaac

                    let pagePath = this.safeExtractRouteName(event) ?? '';

                    try {
                        let route = this.activatedRoute;
                        while (route.firstChild) {
                            route = route.firstChild;
                        }
                        // We define it in the routes to be able to create beautified page names
                        route.data.pipe(take(1)).subscribe((data) => {
                            // You can now use this data
                            pagePath = data?.pageName ?? pagePath;
                            this.analyticsNotifier.notifyPage(pagePath);
                        });
                    } catch (e) {
                        console.log(e);
                    }
                    // const inviteId = route.snapshot.paramMap.get('invite');
                    // if (inviteId) {
                    //   console.log('invite is ' + inviteId);
                    //   this.auth.setInviteId(inviteId);
                    // }
                    console.log(this.router.routerState?.root?.firstChild);
                    console.log('pagePath', pagePath);
                }

                // this.mixpanelService.track('page_view', {
                //   pageName: pageSplitPath,
                //   pageUrl: pagePath
                // });
            });
        }
        // Subscribe to know what should be the state of the menu

        // hostListener.isSideNavOpen.asObservable().subscribe(newSideNavState => this.sideNavOpen = false);
    }

    safeExtractRouteName(event: NavigationEnd) {
        try {
            return event?.urlAfterRedirects?.split('/')?.[1]?.split('?')?.[0] || null; // Return null if any part of the chain fails
        } catch (error) {
            console.error('Error extracting value:', error);
            return null; // Fallback if unexpected errors occur
        }
    }

    ngOnInit(): void {
        // this.router.navigate(['/launch']);

        this.consoleToggleService.disableConsoleInProduction();

        this.analyticsNotifier.initalize();
    }

    jumpToHost() {
        this.navigationService.jumpToHost();
    }

    handleKeyboardEvents(event: KeyboardEvent) {
        const key = event.which || event.keyCode;
        // console.log(key)
    }

    ngOnDestroy(): void {
        clearInterval(this.sessionTimer);
        this.subscriptions.unsubscribe();
    }
}
