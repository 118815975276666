<div *ngIf="this.componentSettings?.onOrOff?.toDisplayToggle" class="audio-controls-wrapper">
    <div class="audio-controls">
        <div class="row-spacebetween">
            <h5 class="no-margin">Audio Controls</h5>
            <mat-slide-toggle
                color="primary"
                (change)="onToggleChange($event)"
                [ngModel]="this.componentSettings.onOrOff.state"
                labelPosition="before">
                {{ this.componentSettings.onOrOff.state ? 'Audio On' : 'Audio Off' }}
            </mat-slide-toggle>
        </div>

        <ng-container
            *ngIf="this.componentSettings?.onOrOff?.state && this.componentSettings?.volume as volumeSettings">
            <div *ngIf="volumeSettings.doDisplay" class="row-centered volume-control">
                <mat-icon class="volume-icon smaller-icon">{{
                    volumeSettings.currentValue > 0 ? 'volume_up' : 'volume_off'
                }}</mat-icon>
                <mat-slider [min]="volumeSettings.min" [max]="volumeSettings.max" [step]="volumeSettings.step">
                    <input
                        matSliderThumb
                        (dragEnd)="onVolumeChange($event)"
                        [value]="volumeSettings.currentValue * volumeSettings.multipleBy" />
                </mat-slider>
                <span class="percentage">
                    {{ volumeSettings.currentValue * volumeSettings.multipleBy | number: '1.0-0' }}%</span
                >
            </div>
        </ng-container>
    </div>
</div>

<div class="voice-list" *ngIf="audioConfigs?.length > 0" [class.disable-list]="toDisableAudio" [class.padded]="padded">
    <app-audio-list-item
        class="voice-item"
        *ngFor="let audio of audioConfigs | audioSort: showAll : selectedAudio?.id; let i = index"
        [audioConfig]="audio"
        [basePath]="basePath"
        (togglePlay)="togglePlay($event)"
        [currentPlayingId]="currentPlayingId"
        [disabled]="this.isLoadingData"
        [selectMode]="showAll"
        (askToChange)="showAll = true"
        (chooseVoice)="chooseVoice($event)">
    </app-audio-list-item>
</div>
