import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SafeHtml } from '@angular/platform-browser';

export interface ICss {
    color: string; // Color inside the ngStyle is not the same so need both
    style: Partial<CSSStyleDeclaration>;
}

export interface ITextSettings {
    htmlContent: SafeHtml;
    align: 'left' | 'center' | 'right';
    css: ICss;
}

export interface IButtonForConfirmationModal {
    text: string;
    css: ICss;
    callback: () => void;
}

export interface IInputSettings {
    type: 'textarea' | 'button' | 'number' | 'mail' | 'text';
    css: ICss;
    value: string;
    label: string;
    placeholder: string;
}

export interface IConfirmationModalData {
    title: ITextSettings;
    body: ITextSettings[];
    input: IInputSettings | null;
    buttons: IButtonForConfirmationModal[];
}

@Component({
    selector: 'app-confirmation-modal',
    templateUrl: './confirmation-modal.component.html',
    styleUrls: ['./confirmation-modal.component.scss'],
})
export class ConfirmationModalComponent {
    constructor(
        public dialogRef: MatDialogRef<ConfirmationModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: IConfirmationModalData
    ) {}

    onButtonClick(button: IButtonForConfirmationModal): void {
        button.callback();

        this.dialogRef.close(button);
    }
}
