import { Component, Inject, Input, OnChanges, OnDestroy, OnInit, Optional, SimpleChanges } from '@angular/core';
import { OnboardingBrandingModeBase } from '../onboarding-branding-mode-base';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BrandingModeEnum } from '../../branding-onboarding.component';
import { ImportedFromType, OnboardingService } from '../../../../../../services/onboarding.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject, takeUntil } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog';
import { IBrandingImportOptions } from '../../../../../../services/http-models/branding/branding-http-request-model';

@Component({
    selector: 'onboarding-branding-automatic-import',
    templateUrl: './branding-automatic-import.component.html',
    styleUrl: './branding-automatic-import.component.scss',
})
export class BrandingAutomaticImportComponent
    extends OnboardingBrandingModeBase
    implements OnChanges, OnInit, OnDestroy
{
    domainForm: FormGroup = new FormGroup({
        companyWebsite: new FormControl('', [
            Validators.required,
            Validators.pattern(/^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,63}$/),
        ]),
    });
    importing = false;
    onDestroy$ = new Subject<boolean>();
    isFirstTime = true;
    isFromBranding = false;
    @Input() isFromHomePage?: boolean = false;
    @Input() to: IBrandingImportOptions = {
        entity: 'user',
        id: null,
    };
    constructor(
        protected onboardingService: OnboardingService,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: any, // Inject dialog data (if opened via MatDialog)
        @Optional() private dialogRef: MatDialogRef<BrandingAutomaticImportComponent> // Inject dialog ref (optional),
    ) {
        super(onboardingService);
        this.isFromBranding = this.data?.branding != null;
        if (this.data) {
            this.to = this.data.to;
        }
    }
    saveCompanyWebsite() {
        if (this.isFromHomePage) {
            return;
        }
        if (this.domainForm.valid) {
            this.branding.company = { domain: this.domainForm.value.companyWebsite };
            this.updateBranding.next({ property: 'company', updateMessage: null });
        }
    }

    ngOnInit(): void {
        //// subscribe only on homepage and branding page.
        if (this.isFromBranding || this.isFromHomePage) {
            if (this.isFromBranding) {
                this.branding = this.data.branding;
            }
            this.onboardingService.branding.pipe(takeUntil(this.onDestroy$)).subscribe((brandingState) => {
                if (this.isFirstTime) {
                    this.isFirstTime = false;
                    return;
                }
                if (brandingState && !brandingState?.errorMessage && this.isFromBranding) {
                    this.dialogRef.close(brandingState.branding);
                }
                this.importing = false;
            });
        }
        this.onboardingService.loading.pipe(takeUntil(this.onDestroy$)).subscribe((loading) => {
            this.importing = loading;
        });
    }
    clearCompanyWebsite() {
        this.domainForm.get('companyWebsite')?.setValue('');
    }

    public async importBrandingByDomain() {
        const formControl = this.domainForm.get('companyWebsite');
        if (!formControl?.valid || formControl.value) {
            // console.error(`Could not import because form control is not valid`);
        }

        //@@@@@@@
        this.importing = true;
        let componentFrom: ImportedFromType;
        if (this.isFromBranding) {
            componentFrom = 'branding';
        } else if (this.isFromHomePage) {
            componentFrom = 'homepage';
        } else {
            componentFrom = 'onboarding';
        }
        this.onboardingService.requestImportBrandIfNeeded(
            false,
            formControl.value,
            true,
            this.isFromBranding || this.isFromHomePage,
            componentFrom,
            this.to
        );
    }

    // Detect on changes to branding
    ngOnChanges(changes: SimpleChanges): void {
        if (changes['branding']) {
            // only if the change is in branding
            if (this.branding) {
                this.domainForm.get('companyWebsite')?.setValue(this.branding?.company?.domain ?? '');
                this.importing = false;
            }
        }
    }
    ngOnDestroy() {
        this.onDestroy$.next(true);
        this.onDestroy$.complete();
    }
    protected readonly BrandingModeEnum = BrandingModeEnum;
}
