import { Component, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { IBranding } from '../../../../../../models/user';
import { OnboardingBrandingModeBase } from '../onboarding-branding-mode-base';
import { FormControl } from '@angular/forms';
import { IColorPalette } from '../../../../../../services/utils/color-palettes.service';
import { Subject, takeUntil } from 'rxjs';
import { ConfigurationService } from '../../../../../../services/configuration.service';
import { BrandingAuthApiService } from '../../../../../../services/api/auth/branding/branding-auth-api.service';
import { OnboardingService } from 'src/app/services/onboarding.service';
import { AnalyticsNotifierService } from '../../../../../../services/utils/analytics-notifier.service';

@Component({
    selector: 'onboarding-branding-mini-logo-colors',
    templateUrl: './branding-mini-logo-colors.component.html',
    styleUrl: './branding-mini-logo-colors.component.scss',
})
export class BrandingMiniLogoColorsComponent extends OnboardingBrandingModeBase implements OnChanges, OnDestroy {
    onDestroy$ = new Subject<boolean>();
    DEFAULT_LOGO_NAME = 'transparent-d832b59d-b37f-4d2f-9797-a90335bd5d28.svg';
    DEFAULT_LOGO_PLACEHOLDER;

    logo = null;
    showCustomPallete = false;
    collorPalleteFormControl = new FormControl();
    customPalette: IColorPalette = { name: 'Custom', value: null };
    defaultPalette: IColorPalette = {
        name: 'Default',
        value: { colorPrimary: '', colorSecondary: '', colorExtra: '' },
    };
    temp = '#ff22ff';

    assetBasePath: string;

    constructor(
        private config: ConfigurationService,
        public brandingAPI: BrandingAuthApiService,
        private analyticsNotifer: AnalyticsNotifierService,
        onboardingService: OnboardingService
    ) {
        super(onboardingService);
        this.assetBasePath = this.config.baseCdnUrl;
        this.DEFAULT_LOGO_PLACEHOLDER = this.assetBasePath + '/files/platform/onboarding/logo-placeholder.svg';
    }

    singleColorChange(ev) {
        console.log(ev);
        this.showCustomPallete = true;
        this.collorPalleteFormControl.setValue(this.customPalette, {
            emitEvent: false,
        });
        this.updateBranding.next({ property: 'colors', updateMessage: 'Brand colors updated successfully' });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['branding'] && changes['branding'].currentValue) {
            // this.setDefaultColors(this.branding);
            // Check if this a default logo
            if (this.branding.logo && this.branding.logo.includes(this.DEFAULT_LOGO_NAME)) {
                this.logo = this.DEFAULT_LOGO_PLACEHOLDER;
            } else {
                this.logo = this.config.baseCdnUrl + this.branding.logo;
            }
        }
    }

    // setDefaultColors(branding: IBranding) {
    //     // console.log('calaaar', branding.colors.colorPrimary);
    //     // PREVIOUS COLORS
    //     this.defaultPalette.value.colorPrimary = branding.colors.colorPrimary;
    //     this.defaultPalette.value.colorExtra = branding.colors.colorExtra;
    //     this.defaultPalette.value.colorSecondary = branding.colors.colorSecondary;
    //
    //     /// Set the picker to the previuos color selection - we call it 'default'
    //     this.collorPalleteFormControl.setValue(this.defaultPalette, {
    //         emitEvent: false,
    //     });
    //     // console.log('calaaar2', branding.colors.colorPrimary);
    //
    //     /// Define logo color pallet
    //     this.defineLogoColorPallete(branding);
    //
    // }

    uploadingLogo() {
        this.brandingAPI
            .get()
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((branding: IBranding) => {
                /// Logo uploaded successfully
                console.log(branding);

                this.onboardingService.isPlaceholderLogo = false;

                /// Update the overall branding
                this.onboardingService.branding.next({ branding, errorMessage: null });

                this.analyticsNotifer.notifyEvent('User Logo From Onboarding Update', {
                    property: 'Logo',
                    newLogoPath: `${this.assetBasePath}${this.branding.logo}`,
                });
            });
    }

    ngOnDestroy() {
        this.onDestroy$.next(true);
        this.onDestroy$.complete();
    }
}
