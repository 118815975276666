import { Injectable } from '@angular/core';
import { BehaviorSubject, take } from 'rxjs';
import { FeatureService } from '../../feature-service.service';
import { catchErrorTypedAsync, FunctionsHelperService } from '../../functions-helper.service';
import { randomArray } from '../../../utils/array-utils';
interface IRandomizedState {
    ai_voice: boolean;
    on_boarding_questions: boolean;
    video_type_wizard: boolean;
    video_type_banner: boolean;
}
@Injectable({
    providedIn: 'root',
})
export class RandomizeStateManagerService {
    private _randomizedStatus$ = new BehaviorSubject<IRandomizedState>(null);
    randomizedObject: IRandomizedState = {
        ai_voice: false,
        on_boarding_questions: false,
        video_type_banner: false,
        video_type_wizard: false,
    };

    public randomizedStatus$ = this._randomizedStatus$.asObservable();

    constructor(
        private featureService: FeatureService,
        private functionsHelper: FunctionsHelperService
    ) {
        this.getRandomizedDataAsync();
    }
    async getRandomizedDataAsync() {
        const results = await Promise.allSettled([
            catchErrorTypedAsync(this.getAiVoiceRandomAsync()),
            catchErrorTypedAsync(this.getOnBoardingQuestionsRandom()),
            catchErrorTypedAsync(this.getVideoTypeBannerRandom()),
            catchErrorTypedAsync(this.getVideoTypeWizardRandom()),
        ]);

        // Ensure all promises resolve before updating the status
        this._randomizedStatus$.next(this.randomizedObject);
    }

    private async getAiVoiceRandomAsync(): Promise<void> {
        const hasAiVoiceFeature = await this.featureService.getFeatureAccessValue('randomize_ai_voice');
        this.randomizedObject.ai_voice = hasAiVoiceFeature;
    }

    private async getOnBoardingQuestionsRandom(): Promise<void> {
        const hasOnboardingFeature = await this.featureService.getFeatureAccessValue('randomize_onboarding_questions');
        this.randomizedObject.on_boarding_questions = hasOnboardingFeature;
    }

    private async getVideoTypeBannerRandom(): Promise<void> {
        const hasVideoTypeBannerFeature = await this.featureService.getFeatureAccessValue('randomize_videotype_banner');
        this.randomizedObject.video_type_banner = hasVideoTypeBannerFeature;
    }

    private async getVideoTypeWizardRandom(): Promise<void> {
        const hasVideoTypeWizardFeature = await this.featureService.getFeatureAccessValue('randomize_videotype_wizard');
        this.randomizedObject.video_type_wizard = hasVideoTypeWizardFeature;
    }
    getRandomStatusAsync() {
        return this.functionsHelper.getDataAsync(this._randomizedStatus$);
    }
    getAndStoreRandomizedState(key: string, arr: any[]) {
        const randomizerData = JSON.parse(localStorage.getItem('randomizer')) || {}; // Get the full stored object
        if (randomizerData[key]) {
            return randomizerData[key]; // Return the stored shuffled array
        }
        arr = this.randomState(arr); // Shuffle array
        this.storeRandomizeState(key, arr); // Store shuffled array

        return arr; // Return shuffled array
    }

    getRandomizeState(key: string) {
        const randomizerData = JSON.parse(localStorage.getItem('randomizer')) || {};
        return randomizerData[key] || null; // Return the stored data or null if not found
    }
    randomState(arr: any[]) {
        return randomArray(arr);
    }
    storeRandomizeState(key: string, arr: any[]) {
        const randomizerData = JSON.parse(localStorage.getItem('randomizer')) || {};
        randomizerData[key] = arr; // Store the provided array as the randomized state
        localStorage.setItem('randomizer', JSON.stringify(randomizerData));
    }

    // Shuffle all items except the last one
    randomExceptLast(arr: any[]) {
        if (arr.length <= 1) return arr;

        const itemsToShuffle = arr.slice(0, -1);
        const lastItem = arr[arr.length - 1];
        const shuffled = randomArray(itemsToShuffle);

        return [...shuffled, lastItem];
    }
}
