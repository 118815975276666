import { Injectable } from '@angular/core';

import { BehaviorSubject, firstValueFrom } from 'rxjs';
import {
    Asset,
    DynamicAsset,
    DynamicValuesChangeMessage,
    IComposition,
    ILayout,
    SflMediaSource,
} from '../models/defines';
import { LoggerService } from './logger.service';
import { PeerService } from './show/peer.service';
import { HttpClient } from '@angular/common/http';
import { ProfileService } from './show/profile.service';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { IExtendedProjectInDTO, IProject, IProjectInDTO } from 'src/app/models/project/project-model';
import { DynamicLottie } from 'lottie-json-helper/lib/dynamic-lottie';
import { DynamicItemType, IDynamicIdentifier, IDynamicItem, IDynamicLottieChange } from 'lottie-json-helper/lib/types';
import { IScene, ISceneInDTO } from 'src/app/models/project/scene-model';
import { ColorsHelper } from 'lottie-json-helper/lib/helpers/colors-helper';
import { IBranding } from '../models/user';

@Injectable()
export class ArtDirectorService {
    DYNAMIC_KEY = 'DYNAMIC-';
    public loadedMediaFiles = new Map<
        string,
        {
            file: File;
            fileUrl: string;
            publishedMedia?: MediaStream;
            started: boolean;
        }
    >();
    loadedAssets = new Map<string, DynamicAsset>();

    private allLottiesDynamicValuesAreReplaced = new BehaviorSubject<boolean>(false);
    /// When the init layout will be loaded (all the json replacement), it will trigger
    public allLottiesDynamicValuesAreReplaced$ = this.allLottiesDynamicValuesAreReplaced.asObservable();

    constructor(
        private config: ConfigurationService,
        private logger: LoggerService,
        private http: HttpClient,
        private peer: PeerService,
        private profileService: ProfileService
    ) {}

    replaceDynamicValues(
        newLottieChangesValues: IDynamicLottieChange[],
        lottieKeysToReplace: IDynamicIdentifier[],
        lottiePath: string
    ) {
        const aa = lottieKeysToReplace
            .map((keyToReplace) => {
                const overrideValue = determineOverrideValue(keyToReplace, newLottieChangesValues ?? [], 'sfl_');
                const lottieChange: IDynamicLottieChange = {
                    id: keyToReplace.id,
                    type: keyToReplace.type,
                    position: keyToReplace.position,
                    value: overrideValue ?? '',
                    placeholder: keyToReplace.placeholder ?? 'Enter input here',
                };
                return lottieChange;
            })
            .filter((value) => !!value);

        return DynamicLottie.updateLottieJson(lottiePath, aa);
    }

    async getBaseJsonAsync(layout: ILayout, baseDesignPath: string) {
        let loadedAsset = this.loadedAssets.get(layout._id);

        if (!loadedAsset) {
            // Load the asset if it wasn't pre-loaded
            await this.preloadAssetAsync(layout, baseDesignPath);
            loadedAsset = this.loadedAssets.get(layout._id);
        }

        return loadedAsset;
    }

    public clearLoadedAssets() {
        this.loadedAssets.clear();
    }

    /**
     * Preloads all compositions related to a project. This function first processes all compositions
     * associated with scenes and then handles any additional compositions not included in scenes.
     * This ensures all assets are loaded before marking the process as complete.
     *
     * @param project - The project for which assets need to be preloaded.
     * @returns A promise that resolves when all asset preloading tasks have completed.
     */
    async preloadProjectAssetsAsync(project: IProject | IProjectInDTO): Promise<void> {
        // Check if the project is valid and has scenes to process
        if (!project?.designGroup?.chosenDesign || !project.scenes || project.scenes.length === 0) {
            console.warn('Project has no scenes?', project);
            return; // Exit early if project is not set up correctly
        }

        const design = project.designGroup.chosenDesign;
        const scenes = project.scenes;

        // Array to hold promises for loading scene-based compositions
        const sceneBasedCompositionsLoaded: Promise<void>[] = [];

        // Array to hold promises for loading non-scene compositions
        const nonSceneBasedCompositionsLoaded: Promise<void>[] = [];

        // Process each scene and preload all compositions within it
        scenes.forEach((scene: IScene | ISceneInDTO): Promise<void> => {
            // Skip scenes without a valid composition or layout data
            if (!scene || !scene.composition || !scene.composition.layouts) {
                return;
            }
            // Preload all layouts for the current scene's composition
            scene.composition.layouts.forEach((layout) => {
                console.log(layout.lottiePath);

                sceneBasedCompositionsLoaded.push(this.preloadAssetAsync(layout, design.basePath));
            });
        });

        // Handle compositions that are not part of any scene
        if (design.compositions) {
            const excludedCompositions = design.compositions.filter(
                (composition) => !scenes.some((scene) => scene.composition?._id === composition?._id)
            );

            // Process each non-scene composition
            excludedCompositions.forEach((composition) => {
                // Skip compositions without layouts
                if (!composition || !composition.layouts) return;

                // Preload all layouts for the current composition
                composition.layouts.forEach((layout) => {
                    nonSceneBasedCompositionsLoaded.push(this.preloadAssetAsync(layout, design.basePath));
                });
            });
        }

        // Wait for all compositions, both scene-based and non-scene-based, to finish loading
        Promise.allSettled([...sceneBasedCompositionsLoaded, ...nonSceneBasedCompositionsLoaded]).then(() => {
            console.log('Project preloaded', project);
            console.log('Project assets', this.loadedAssets);
        });

        // Signal that all dynamic values have been replaced (consider renaming this for clarity)
        this.allLottiesDynamicValuesAreReplaced.next(true);
    }

    findLottiesForTemplate(template: IComposition) {
        // if (!template) {
        //     console.warn(
        //         `Could not find lotties for template because template is null .`
        //     );
        //     return;
        // }
        // const loadedLotties: string[] = [];
        // template.layouts.forEach((layout) => {
        //     let loadedAsset = this.loadedAssets.get(layout.dynamicClientLayerId);
        //     loadedLotties.push(
        //         loadedAsset?.substitute ?? this.getLottieJsonPath(layout.cutoutPath)
        //     );
        // });

        // return loadedLotties;
        return null;
    }

    getLottieJsonPath(baseDesignPath: string, lottieUrl: string) {
        // if(!asset.name || ! asset.path){
        //   return ''
        // }
        const assetBasePath = this.config.baseCdnUrl;
        return `${assetBasePath}${baseDesignPath}${lottieUrl}`;
        // return `${assetBasePath}/files/all/${asset.name}.${asset.type}?bitId=${asset.forBitId}`;
    }

    publishVideo(event, file) {
        /// Get the video element from the event
        if (this.loadedMediaFiles.get(file)?.started) {
            return;
        }
        // console.log('Publishing video file')
        this.loadedMediaFiles.get(file).started = true;

        const videoElement = event.target;
        // console.log(videoElement)
        const media = videoElement.captureStream(30);
        this.logger.debug('export media: %o.', media);

        /// Add the published stream to the map
        this.loadedMediaFiles.get(file).publishedMedia = media;

        // console.log('testinggg', this.loadedMediaFiles)
        videoElement.muted = true;
        videoElement.loop = true;
        videoElement.play();

        const executed = false;
        // setTimeout(() => {
        //     videoElement.addEventListener(
        //         'timeupdate',
        //         (event) => {
        //             // console.log('On time update', videoElement.currentTime)
        //             if (parseInt(videoElement.currentTime) === 0) {
        //                 // console.log('Video element looped', event)
        //                 if (!executed) {
        //                     executed = true;
        //                     this.formatManager.notifyDirectorCommand.next(
        //                         DirectorCommand.NEXT
        //                     );
        //                 }
        //             }
        //         },
        //         true
        //     );
        // }, 1200); /// At least one second

        // Start producing audio and video
        this.peer.produceVideo(media, SflMediaSource.media);
        // console.log('Art 4', producer)
        this.peer.produceAudio(media, SflMediaSource.media);
    }

    async downloadLottieAndInitialize(layout: ILayout, baseDesignPath: string) {
        const lottiePath = this.getLottieJsonPath(baseDesignPath, layout.lottiePath);

        // this.loadedAssets.set(layout.dynamicClientLayerId, {
        //     id: layout.dynamicClientLayerId,
        //     path: lottiePath,
        // });

        // Download the asset, add no-auth to fix any auth issues with azure blob storage
        let content = await firstValueFrom(
            this.http.get(lottiePath, {
                responseType: 'text',
                params: { 'no-auth': true },
            })
        );

        // Update font location and relative asset path for the lottie
        content = DynamicLottie.updateLottieAssetsPathAndFonts(content, baseDesignPath, this.config.baseCdnUrl);

        const contentObject = JSON.parse(content);
        const lottieWidth = contentObject.w;
        const lottieHeight = contentObject.h;
        const assetsNodes = contentObject.assets;
        // console.log('assetsNodes', assetsNodes)
        const dynamicNodes = DynamicLottie.findFullDynamicNodes(contentObject, false);
        const filteredIdentifiers = removeIdentifiersDuplicatesById(dynamicNodes);
        const dynamicAsset: DynamicAsset = {
            id: layout._id,
            content: content,
            dynamics: filteredIdentifiers ?? [],
            path: lottiePath,
            width: lottieWidth,
            height: lottieHeight,
        };
        return {
            assetsNodes,
            dynamicNodes,
            dynamicAsset,
        };
    }

    public getDynamicLottieChangesToSend(
        lottieKeysToReplace: IDynamicLottieChange[],
        valuesToSwitch: IDynamicLottieChange[]
    ) {
        const updatedKeysToReplaceInLottie: IDynamicLottieChange[] = lottieKeysToReplace
            .map((keyToReplace) => {
                const neyValue = valuesToSwitch.find((dynamicValue) =>
                    isEqualIgnoringPrefix(keyToReplace.id, dynamicValue.id, 'sfl_')
                );
                return neyValue;
            })
            .filter((value) => !!value);

        return updatedKeysToReplaceInLottie;
    }

    /// Todo: unify with the server - put it in the lottie lib
    public extractDynamicLottieByUserBranding(project?: IExtendedProjectInDTO, overrideBranding?: Partial<IBranding>) {
        const user = this.profileService?.user$.value;

        // If project.branding does not exist, default to user.branding
        let branding: Partial<IBranding> = project?.branding ?? user?.branding;
        branding = overrideBranding ?? branding;

        const valuesFromUser: IDynamicLottieChange[] = [
            {
                id: DynamicItemType.LOGO + 0,
                type: DynamicItemType.LOGO,
                position: 0,
                value: branding.logo,
                placeholder: '',
            },
            {
                id: DynamicItemType.LOGO + 1,
                type: DynamicItemType.LOGO,
                position: 1,
                value: branding.logoW ?? branding.logo,
                placeholder: '',
            },
            {
                id: DynamicItemType.USERNAME + 0,
                type: DynamicItemType.USERNAME,
                position: 0,
                value: project ? project.user.firstName + project.user.lastName : user?.name ?? '',
                placeholder: '',
            },
            {
                id: DynamicItemType.FILL + 0,
                type: DynamicItemType.FILL,
                position: 0,
                value: ColorsHelper.hexToLottieRGBA(branding.colors?.colorPrimary),
                placeholder: '',
            },
            {
                id: DynamicItemType.FILL + 1,
                type: DynamicItemType.FILL,
                position: 1,
                value: ColorsHelper.hexToLottieRGBA(branding.colors?.colorSecondary),
                placeholder: '',
            },
            {
                id: DynamicItemType.FILL + 2,
                type: DynamicItemType.FILL,
                position: 2,
                value: ColorsHelper.hexToLottieRGBA(branding.colors?.colorExtra),
                placeholder: '',
            },
            {
                id: DynamicItemType.STROKE + 0,
                type: DynamicItemType.STROKE,
                position: 0,
                value: ColorsHelper.hexToLottieRGBA(branding.colors?.colorPrimary),
                placeholder: '',
            },
            {
                id: DynamicItemType.STROKE + 1,
                type: DynamicItemType.STROKE,
                position: 1,
                value: ColorsHelper.hexToLottieRGBA(branding.colors?.colorSecondary),
                placeholder: '',
            },
            {
                id: DynamicItemType.STROKE + 2,
                type: DynamicItemType.STROKE,
                position: 2,
                value: ColorsHelper.hexToLottieRGBA(branding.colors?.colorExtra),
                placeholder: '',
            },
            {
                id: DynamicItemType.COMPANY + 0,
                type: DynamicItemType.COMPANY,
                position: 0,
                value: branding.company?.name,
                placeholder: '',
            },
            {
                id: DynamicItemType.EMAIL + 0,
                type: DynamicItemType.EMAIL,
                position: 0,
                value: project ? project.user.email : user?.email ?? '',
                placeholder: '',
            },
            {
                id: DynamicItemType.WEBSITE + 0,
                type: DynamicItemType.WEBSITE,
                position: 0,
                value: branding.company?.domain,
                placeholder: '',
            },
            {
                id: DynamicItemType.USERTITLE + 0,
                type: DynamicItemType.USERTITLE,
                position: 0,
                value: 'Job Title',
                placeholder: '',
            },
            // {
            //     id: DynamicItemType.HEADER + 0,
            //     type: DynamicItemType.HEADER,
            //     position: 0,
            //     value: project ? project.launchAnswers?.answer : ' ',
            //     placeholder: '',
            // },
        ].filter((item) => !!item.value);

        return valuesFromUser;
    }

    private async preloadAssetAsync(layout: ILayout, baseDesignPath: string) {
        // Assign a unique ID for each layout+composition combination.
        // this.lottieLayersService.assignDynamicLayerId(composition)

        const { dynamicNodes, assetsNodes, dynamicAsset } = await this.downloadLottieAndInitialize(
            layout,
            baseDesignPath
        );
        // Actually add to loaded assets, means we completed the loading process
        this.loadedAssets.set(layout._id, dynamicAsset);
    }
}

function removeIdentifiersDuplicatesById(dynamicItems: IDynamicItem[]) {
    const seenIds = new Set<string>();
    return dynamicItems.flatMap((dynamicItem) => {
        return dynamicItem.identifiers.filter((item) => {
            if (seenIds.has(item.id)) {
                return false;
            } else {
                seenIds.add(item.id);
                return true;
            }
        });
    });
}

function determineOverrideValue(
    identifier: IDynamicIdentifier,
    dynamicLottieValues: IDynamicLottieChange[],
    prefix: string
) {
    const valueFromUser = dynamicLottieValues.find((dynamicLottieValueFromUser) =>
        isEqualIgnoringPrefix(identifier.id, dynamicLottieValueFromUser.id, prefix)
    );
    return valueFromUser?.value ?? identifier.defaultValue;
}

function isEqualIgnoringPrefix(s1: string, s2: string, prefix: string): boolean {
    if (s1.startsWith(prefix)) {
        s1 = s1.slice(prefix.length); // Remove the prefix from the first string
    }
    if (s2.startsWith(prefix)) {
        s2 = s2.slice(prefix.length);
    }
    return s1 === s2; // Compare the modified first string with the second string
}
