import { Injectable } from '@angular/core';
import { IBranding } from '../models/user';
import { ProfileService } from './show/profile.service';
import { BrandingAuthApiService } from './api/auth/branding/branding-auth-api.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { SnackbarActionEnum, SnackBarService } from './utils/snack-bar.service';
import { HttpResponse } from '@angular/common/http';
import { ConfirmationModalComponent } from '../components/dialogs/confirmation-modal/confirmation-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { AnalyticsNotifierService } from './utils/analytics-notifier.service';
import { IBrandingImportOptions } from './http-models/branding/branding-http-request-model';
export interface BrandingState {
    branding: IBranding | null;
    errorMessage: string | null;
}
export type ImportedFromType = 'onboarding' | 'homepage' | 'branding' | null;
@Injectable()
export class OnboardingService {
    public branding = new BehaviorSubject<BrandingState>({
        branding: null,
        errorMessage: null,
    });
    public isPlaceholderLogo = false;
    public loading = new BehaviorSubject<boolean>(false);
    public noLogoNotifyOnboarding = new Subject<boolean>();
    constructor(
        private profileService: ProfileService,
        private brandingAPIService: BrandingAuthApiService,
        private snackBarService: SnackBarService,
        private dialog: MatDialog,
        private analyticsNotfier: AnalyticsNotifierService
    ) {}
    /**
     * Requests and updates branding information based on the domain and context.
     *
     * @param {boolean} initial - Indicates if this is the initial branding fetch (default: `true`).
     * @param {string | null} domain - The domain to fetch branding for, normalized to lowercase if provided.
     * @param {boolean} fromUser - check if the request coming from the user import url.
     *     if its from user, dont update the branding if its the default branding.
     *
     * - Fetches branding using `brandingAPIService.import()`.
     * - On success, updates `branding` and user branding in `profileService`.
     * - On error:
     *   - Updates fallback branding if not initiated by a user (`!fromUser`).
     *   - Displays an error message for invalid domains.
     * - Always notifies the `branding` observable.
     */
    requestImportBrandIfNeeded(
        initial: boolean,
        domain: string,
        fromUser: boolean,
        waitForAbout: boolean,
        importedFrom: ImportedFromType,
        to: IBrandingImportOptions
    ) {
        // console.log('Bringing brand', initial)
        if (domain) {
            domain = domain.toLowerCase();
        }
        if (fromUser) {
            this.analyticsNotfier.notifyEvent('Import Requested', {
                importedFrom,
                website: domain,
            });
        }
        this.brandingAPIService.import(initial, domain, waitForAbout, to).subscribe({
            next: (responseBody: { branding: IBranding; isPlaceholderLogo: boolean; aboutFound: boolean }) => {
                // Access the body of the response
                const branding = responseBody?.branding;
                if (branding) {
                    // if the request coming from the first auto request url.
                    this.isPlaceholderLogo = responseBody?.isPlaceholderLogo;
                    if (!fromUser) {
                        this.profileService.updateUserBranding(JSON.parse(JSON.stringify(responseBody.branding)));
                        this.branding.next({ branding: responseBody.branding, errorMessage: null });
                        return;
                    }
                    // if no logo and no about
                    if (!responseBody.aboutFound && responseBody.isPlaceholderLogo) {
                        this.snackBarService.openMessage(
                            "Oops! We couldn't recognize that URL. Please review it and try again",
                            SnackbarActionEnum.Dismiss,
                            5000
                        );
                        this.analyticsNotfier.notifyEvent('Import Failed', {
                            website: domain,
                        });
                        return;
                    }
                    // if logo and no about
                    if (!responseBody.aboutFound && !responseBody.isPlaceholderLogo) {
                        const text = this.getMessage(importedFrom, branding, responseBody);
                        this.openConfirmationDialog(text);
                        this.branding.next({
                            branding: branding,
                            errorMessage: null,
                        });
                    }
                    // if no logo and yes about
                    else if (responseBody.aboutFound && responseBody.isPlaceholderLogo) {
                        const text = this.getMessage(importedFrom, branding, responseBody);
                        if (importedFrom === 'onboarding') {
                            this.openOnboardingNoLogoDialog(text);
                        } else {
                            this.openConfirmationDialog(text);
                        }
                        this.branding.next({
                            branding: branding,
                            errorMessage: null,
                        });
                    }
                    // if logo and about
                    else {
                        this.isPlaceholderLogo = responseBody.isPlaceholderLogo;
                        // Update branding with the response data
                        this.profileService.updateUserBranding(JSON.parse(JSON.stringify(responseBody.branding)));
                        this.branding.next({ branding: responseBody.branding, errorMessage: null });
                    }
                }
                this.analyticsNotfier.notifyEvent('Import Successful', {
                    website: domain,
                    logoFound: !responseBody.isPlaceholderLogo,
                    aboutFound: responseBody.aboutFound,
                });
            },
            error: (error) => {
                console.warn('Error importing branding:', error);
                this.analyticsNotfier.notifyEvent('Import Failed', {
                    website: domain,
                });
            },
            complete: () => {
                this.loading.next(false);
            },
        });
    }

    openConfirmationDialog(text: string) {
        const dialogRef = this.dialog.open(ConfirmationModalComponent, {
            width: '550px',
            data: {
                title: {
                    htmlContent: `Branding Imported`,
                    align: 'center',
                    css: {
                        color: '',
                        style: {},
                    },
                },
                body: [
                    {
                        htmlContent: text,
                        align: 'center',
                        css: {
                            color: '',
                            style: {
                                width: '100%',
                            },
                        },
                    },
                ],
                buttons: [
                    {
                        text: 'Confirm',
                        css: {
                            color: 'primary',
                            style: {},
                        },
                        callback: async () => {
                            dialogRef.close(); // Close the dialog when "Next" is clicked
                        },
                    },
                ],
            },
        });
    }

    openOnboardingNoLogoDialog(text: string) {
        const dialogRef = this.dialog.open(ConfirmationModalComponent, {
            width: '550px',
            data: {
                title: {
                    htmlContent: `You're halfway there! 🎉`,
                    align: 'center',
                    css: {
                        color: '',
                        style: {},
                    },
                },
                body: [
                    {
                        htmlContent: text,
                        align: 'center',
                        css: {
                            color: '',
                            style: {
                                width: '100%',
                            },
                        },
                    },
                ],
                buttons: [
                    {
                        text: 'Skip for now',
                        css: {
                            color: 'default',
                            style: {},
                        },
                        callback: async () => {
                            dialogRef.close();
                            this.noLogoNotifyOnboarding.next(false);
                        },
                    },
                    {
                        text: 'Upload Logo',
                        css: {
                            color: 'primary',
                            style: {},
                        },
                        callback: async () => {
                            dialogRef.close();
                            this.noLogoNotifyOnboarding.next(true);
                        },
                    },
                ],
            },
        });
    }
    getMessage(importedFrom, branding, responseBody) {
        const messages = {
            noAbout: {
                onboarding: `We pulled your logo! Add a company description in the branding section for a personalized experience.`,
                branding: `We pulled your logo. Add your company description in the About Section.`,
                homepage: `To get personalized video ideas, complete your company's profile in the branding section.`,
            },
            noLogo: {
                onboarding: `We’ve gathered your content for ${branding.company.domain}.</br>Just upload your logo to get the best results!`,
                branding: `We pulled your content from ${branding.company.domain}. You can add your logo here.`,
                homepage: `To get branded videos, upload your logo in the branding section.`,
            },
        };
        if (!responseBody.aboutFound && !responseBody.isPlaceholderLogo) {
            // Case: Logo exists but no about section
            return messages.noAbout[importedFrom] || '';
        } else if (responseBody.aboutFound && responseBody.isPlaceholderLogo) {
            // Case: About section exists but no logo
            return messages.noLogo[importedFrom] || '';
        }
        return ''; // Default message if no cases match
    }
}
